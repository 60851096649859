import React from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import rocketmanTransport from '../../../services/RocketmanTransport';
import { useRocketmanTransport } from '../../rocketman/RocketmanTransportContext';
import Currency from '../../Currency/Currency';
import JackpotOdometer from '../../JackpotOdometer';

export default function RocketPotHolder() {

const rocketmanContext = useRocketmanTransport();
const jackpotData = rocketmanContext.jackpotData;
const { width } = useWindowDimensions();

const withoutDecimals = () => {
  if (width < 400 || (width >= 1024 && width < 1200) || (rocketmanTransport.amountFormat !== "" && !/\./.test(rocketmanTransport.amountFormat)) || rocketmanTransport.currencyNumberOfDigits === 0) {
    return true;
  }
  else return false;
}

  return (
    <div className="jackpot-cash-holder">
      {rocketmanTransport.retail ? <span style={{ marginBottom: -6}} className='jackpot-title-retail mr-2'>ROCKETPOT</span> : null} <JackpotOdometer value={Number(Number(jackpotData.rocketpot) * Number(rocketmanTransport.myExchangeRate))} format={withoutDecimals() ? 0 : rocketmanTransport.noOfDecimals} duration={60} />  <Currency className={"jackpot-currency " + (rocketmanTransport.r7css ? "r7css" : "")}>{rocketmanTransport.myCurrency}</Currency>
    </div>
  )
}
