import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import rocketmanTransport from "../services/RocketmanTransport";
import translate from "../services/Translation/lang";
import CreditSvg from "./svg/credit-svg";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";
import Currency from "./Currency/Currency";

const HeaderCredit = (props) => {

  const [spamFilter, setSpamFilter] = useState(false);
  const [balance, setBalance] = useState(0);
  window.setBalance = setBalance;

const handleBalance = () => {
  if (!spamFilter) {
    rocketmanTransport.requestUserBalanceInAbstract()
    setSpamFilter(true)
    setTimeout(() => {
      setSpamFilter(false)
    }, 3000);
  }
  else return
}


  return (
    <div data-tip={ translate("balance") + "<br />" + balance } data-multiline={true} className={"header-credit text-white " + (rocketmanTransport.r7css ? "r7css" : "")}>
      <CreditSvg className="cursor-pointer" onClick={handleBalance} />
      {currencyFormatter(balance)} <Currency className={"balance-currency " + (rocketmanTransport.r7css ? "r7css" : "")}>{rocketmanTransport.myCurrency}</Currency>
      <ReactTooltip
        className={"bet-tooltip " + (rocketmanTransport.r7css ? "r7css" : "")}
        place="bottom"
        effect="solid"
        backgroundColor="#ffbf19"
      />
    </div>
  );
};

export default HeaderCredit;
