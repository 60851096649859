import React, { useEffect } from "react";
import rocketmanTransport from "../services/RocketmanTransport";
import ScrollAnimation from "react-animate-on-scroll";
import soundFxPlayer from "../services/Audio/SoundFxPlayer";
import translate from "../services/Translation/lang";
import Currency from "./Currency/Currency";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";

const HeaderDialog = (props) => {
  const OFFSET = 80;

  useEffect(() => {
    const expired = setTimeout(() => {
      window.setCashedOuts(
        props.cashedOuts.filter((c, i) => i !== props.index)
      );
      rocketmanTransport.cashouts = props.cashedOuts.filter(
        (c, i) => i !== props.index
      );
      clearTimeout(expired);
    }, 3500);

    //return () => clearTimeout(expired);
  }, [props.cashedOuts, props.index]);

  return (
    <ScrollAnimation
      animateOnce
      offset={OFFSET}
      delay={200}
      duration={1}
      animateIn="fadeInDown"
      animateOut="fadeInUp"
    >
      <div className="cashout-header-dialog">
        <div>
          {translate("you_have_cashed_out")}
          <span className="cashout-header-dialog-amount">
            {currencyFormatter((props.amount))}
          </span>{" "}
          <Currency>{rocketmanTransport.myCurrency}</Currency>!
        </div>
        <button
          className="cashout-header-dialog-button close-container bet-info-close"
          onClick={() => {
            soundFxPlayer.play("you_have_cashed_out_close");
            window.setCashedOuts(
              props.cashedOuts.filter((c, i) => i !== props.index)
            );
            rocketmanTransport.cashouts = props.cashedOuts.filter(
              (c, i) => i !== props.index
            );
          }}
        >
          <div className="leftright"></div>
          <div className="rightleft"></div>
        </button>
      </div>
    </ScrollAnimation>
  );
};

export default HeaderDialog;
