import { io } from "socket.io-client";
import config from "../config/config";

let DEBUG;

function dlog(msg){
	if (DEBUG){
		console.log("DEBUG@" + new Date().toISOString() + ": " + msg.toString());
	}
}

class TransportAbstract {
 	constructor (_, platformParams, serverKeepaliveTimeout, secure_conn = true) {
 		this.gameServer = config[platformParams.region].gameServers;
 		this.chatServer = config[platformParams.region].chatServer;
		this.sid = null;
		this.transportInitialized = false;
		this.platformParams = platformParams;
		this.gameStateServerSubscription = null;
		this.serverKeepaliveTimeout = serverKeepaliveTimeout;
		this.conn = null;
		this.chat = [];
		this.chatConn = null;
		this.lastGameStateSrvMsgTs = 0;
		this.allLoginData = null;
		this.lastCashoutTsPerTicketId = {} // attempt to fix cashout spam
		this.lastBetTsPerButtonId = {} // attempt to fix bet spam
		this.waitingBetAckButtonIds = []
		this.companyId = null
		this.chatRoom = null
		this.currency = null
		this.retail = platformParams.retail;

		try{
        	DEBUG = window.location.search.includes("debug=true")
		}catch{}

        dlog("CONFIG: " + JSON.stringify(config))
        dlog("platformParams: " + JSON.stringify(platformParams))

		this.conn = io(this.gameServer, {secure: true, transports: ['websocket'], upgrade: false});

        if (DEBUG){
			this.conn.onAny((event, ...args) => {
		 		dlog(`SRVEVENT: ${event} ${JSON.stringify(args)}`);
			});
        }

		this.conn.on("connect_error", (err) => {  
			console.log("connect_error");
			console.log(JSON.stringify(err))
			console.log(err.message)
		});

	  	this.conn.on("connect", (data) => {
        dlog("event connect")
	  		this.connected()
				if (this.retail) {
					// if retail turn sid to null so that it calls again login data
					this.sid = null;
				}
	  		if (this.sid === null){
	  			this.conn.emit('login', this.platformParams)
	  		}else{
  				this.conn.emit('rememberme', this.sid)
	  		}
		});

	  	this.conn.on("disconnect", (data) => {
        	dlog("event disconnect")
	  		this.lostConn()
		});

	  	this.conn.on("login", (data) => {
        	dlog("event login: " + JSON.stringify(data))
	  		this.sid = data.sid;
	  		this.allLoginData = JSON.parse(JSON.stringify(data));
	  		this.login(data);
    		this.transportInitialized = true;
			this.conn.emit('rememberme', this.sid)
			setTimeout(function(){
				if(!this.retail) {
					this.registerOnChatServer();
				}
			}.bind(this),300)

	 		if (DEBUG && window.location.href.includes("etdev")){
	 			window.setWarning("sid: " + this.sid.toString() + " " + "| srv_sig: " + data.srv_sig)
	 		}
		});

	  	this.assignGameEvents()
  	}

  	// CHAT

	async registerOnChatServer(){
    	dlog("register on chat server")
		if (this.chatConn == null || this.chatConn.readyState != 1){
			this.companyId = this.allLoginData.launcher_params?.companyId || this.platformParams.companyId
			this.chatRoom = this.allLoginData.launcher_params?.chat_room || this.platformParams.chatRoom
			this.currency =  this.allLoginData.launcher_params?.currency || this.allLoginData.player_data?.currency_code
			if (this.chatRoom == "None"){
				this.chatRoom = null
			}
			if (!this.chatRoom){
				if (this.companyId == 1){
					this.chatRoom = "demo"
				}else{
					this.chatRoom = this.platformParams.language
				}
			}
			// fix for en africans
			const enAf = ["DZ","AO","BJ","BW","BF","BI","CM","CV","CF","TD","KM","CG","CD","CI","DJ","EG","GQ","ER","ET","GA","GM","GH","GN","GW","KE","LS","LR","LY","MG","ML","MW","MR","MU","YT","MA","MZ","NA","NE","NG","RE","RW","ST","SN","SC","SL","SO","ZA","SS","SD","SZ","TZ","TG","TN","UG","EH","ZM","ZW"]
			if (this.chatRoom && this.chatRoom.toString().toLowerCase() == "en" && this.allLoginData.player_data.country_code && enAf.includes(this.allLoginData.player_data.country_code.toString().toUpperCase())){
				this.chatRoom = "en-Af"
			}
			if (this.chatRoom && this.chatRoom.toString().toLowerCase() == "en" && this.allLoginData.player_data.country_code && this.allLoginData.player_data.country_code.toString().toUpperCase() == "LT"){
				this.chatRoom = "en-Lt"
			}
			this.chatConn = new WebSocket(this.chatServer);
			var registerFunc = function (event) {
            	var registerString = JSON.stringify({ method: 'REGISTER', 
								company_id: this.companyId,
								chat_room: this.chatRoom,
								sid: this.sid, 
								player_id: this.player_id,
								uid: this.platformParams.uid, 
								display_name:this.allLoginData.player_data.username,  
								country_code:this.allLoginData.player_data.country_code,  
								language:this.platformParams.language,  
								currency: this.currency,
								avatar_id:this.avatarId});
            	dlog(registerString)
            	this.chatConn.send(registerString);
            	this.requestTotalOnline()
            }.bind(this)
            this.chatConn.onopen = registerFunc
            this.chatConn.onclose = registerFunc
            this.chatConn.onmessage = function (event) {
                let data = JSON.parse(event.data);
				dlog("chat_data " + event.data)
				 //console.log("chat_data", data);
								if (data?.message_not_sent) {
									this.msgNotSent(data.message_not_sent);
									return;
								}
                switch (data.type) {
                    case 'chat_like':
                    	let chatMsg = this.chat.find(m => m.id === data.message_id)
                    	if (chatMsg.likes_who.includes(data.who)){
							chatMsg.likes_who = chatMsg.likes_who.filter(who => who !== data.who)
                    	}else{
                    		chatMsg.likes_who.push(data.who)
                    	}
                		chatMsg['likes'] = parseInt(chatMsg['likes_who'].length)
                        break;
                    case 'chat_message':
                        this.chat.unshift(data.message)
                        if (this.chat.length > 100){
                        	this.chat.slice(0,100)
                        }
                        if (data.total_online){
                        	this.totalOnline(data.total_online)
                        }
						this.newChatMessage(data.message)
                        break;
                    case 'chat_history':
                    	this.chat = data.messages.reverse()
                    	for (const chatMsg of this.chat) {
                			chatMsg['likes'] = parseInt(chatMsg['likes_who'].length)
						}
                        break;
                    case 'remchat':
                    	this.chat = this.chat.filter(item => item.id.toString() !== data["id"].toString());
                        break;
                    case 'total_online':
						this.totalOnline(data["users"])
                        break;
										case 'meteor_offer':
											this.chat.unshift(data.message)
											this.incomingOfferMeteorShower()
											if (this.chat.length > 100){
												this.chat.slice(0,100)
											}
											break;
											
										case 'meteor_claim':
											let meteorMsg = this.chat.find(m => m.msg == data.message.msg);
											let formattedMsg = {};
											formattedMsg[`key-${data.message.player_id}`] = data.message.username;
											meteorMsg['claims'] = {...meteorMsg['claims'], ...formattedMsg};
											meteorMsg['claimed'] = true;

                      break;
                    default:
                        console.error(
                            "unsupported event", data);
                }
                this.chatUpdate(this.chat)
            }.bind(this);
            this.chatConn.onclose = function (event) {
				setTimeout(function(){
					if(!this.retail) {
						this.registerOnChatServer();
					}
				}.bind(this),3000)
            }.bind(this);
		}
	}

	async requestTotalOnline(){
		dlog("requestTotalOnline")
		if (this.chatConn.readyState == 1) this.chatConn.send(JSON.stringify({ method: 'TOTAL_ONLINE', chat_room: this.chatRoom}));
	}

	async requestChatLike(msg_id=-1, is_meteor = false, msg = '') {
		dlog("requestChatLike")
		if (this.chatConn.readyState == 1) this.chatConn.send(JSON.stringify({ method: 'LIKE', chat_room: this.chatRoom, 
			msg_id: msg_id, is_meteor, company_id: this.companyId, msg}));
	}

	async requestSendChatMessage(msg=""){
		dlog("requestSendChatMessage " + msg.toString())
		if (this.chatConn.readyState == 1) this.chatConn.send(JSON.stringify({ method: 'MSG', chat_room: this.chatRoom, text: msg}));
	}

	// GAME SERVER

  	async assignGameEvents(){
  		this.conn.on("game_state", (data) => {if (this.transportInitialized) {
				if (this.retail) {
					this.retailGameStateUpdate(data)
				} else {
					this.gameStateUpdate(data)
				}
			}});
	  	this.conn.on("bet_ack", async (data) => {
	  		await this.betAck(data)
	  		this.waitingBetAckButtonIds = this.waitingBetAckButtonIds.filter(id => id != data["bet_ack"]["bet_button_id"]);
	  	});
	  	this.conn.on("bet_cancel_ack", (data) => {this.betCancelAck(data)});
	  	this.conn.on("cashout_ack", (data) => {this.cashoutAck(data)});
	  	this.conn.on("play_free_tickets_ack", (data) => {this.playedFreeTickets(data)});
	  	this.conn.on("tick_bets", (data) => {this.tickBets(data)});
			this.conn.on("claim_meteor_ticket_ack", (data) => {this.claimMeteorTicketAck(data)});
	  	this.conn.on("tick_canceled_bets", (data) => {this.tickCanceledBets(data)});
	  	this.conn.on("tick_cashouts", (data) => {this.tickCashouts(data)});
	  	this.conn.on("exception", (data) => {
	  		this.exception(data)
	  		// FIX: can't bet after exception
  			setTimeout(function(){
  				try{
  					this.waitingBetAckButtonIds = [];
  				}catch{}
  			}.bind(this),1000)
	  	});
			if (this.retail) {
				this.conn.on("tickets", (data) => {this.retailTickets(data)});
			}
	  	this.conn.on("round_hash", (data) => {this.roundHashData(data)});
	  	this.conn.on("round_mp_by_hash", (data) => {this.roundMpByHash(data)});
	  	this.conn.on("jackpot_data", (data) => {this.jackpotData(data)});
	  	this.conn.on("player_bonus_history", (data) => {this.playerBonusHistory(data)});
	  	this.conn.on("jackpot_history_data", (data) => {this.jackpotHistory(data)});
	  	this.conn.on("direct_message", (data) => {this.notification(data)});
			this.conn.on("session_status", (data) => {this.sessionStatus(data)});
	  	this.conn.on("ticket_details", (data) => {this.ticketDetails(data)});
	  	this.conn.on("user_balance", (data) => {this.userBalance(data)});
	  	this.conn.on("bet_wins", (data) => {
			const top_type = data['top_type']
			const days_back = data['days_back']
			const bet_wins = data['bet_wins']
			if (top_type === 1 && days_back === 7) this.topWinsWeekly(bet_wins)
			if (top_type === 1 && days_back === 30) this.topWinsMonthly(bet_wins)
			if (top_type === 1 && days_back === 365) this.topWinsYearly(bet_wins)
			if (top_type === 2 && days_back === 7) this.topOddsWeekly(bet_wins)
			if (top_type === 2 && days_back === 30) this.topOddsMonthly(bet_wins)
			if (top_type === 2 && days_back === 365) this.topOddsYearly(bet_wins)
			if (top_type === 3 && days_back === 7) this.biggestOddsWeekly(bet_wins)
			if (top_type === 3 && days_back === 30) this.biggestOddsMonthly(bet_wins)
			if (top_type === 3 && days_back === 365) this.biggestOddsYearly(bet_wins)
		});

		this.conn.on("tournament_info", (data) => {this.tournamentInfo(data)})
		this.conn.on("tournament_results_in_progress", (data) => {this.tournamentResultsInProgress(data)});
		this.conn.on("player_tournaments_history", (data) => {this.playerTournamentsHistory(data)});
		this.conn.on("tournament_personalized_results_history", (data) => {this.tournamentPersonalizedResultsHistory(data)});
		this.conn.on("top3", (data) => {this.highScoresRound(data)});
  	}

  	async disconnect(){
		dlog("disconnect")
		this.conn.disconnect()
  	}

	async tournamentInfo(data) {
		dlog(`TBI tournamentInfo, got ${JSON.stringify(data)}`)
	}

	async tournamentResultsInProgress(data) {
		dlog(`TBI tournamentResultsInProgress, got ${JSON.stringify(data)}`)
	}

	async playerTournamentsHistory(data) {
		dlog(`TBI playerTournamentsHistory, got ${JSON.stringify(data)}`)
	}

	async getTournamentInfo() {
		dlog("getTournamentInfo");
		this.conn.emit('get_tournament_info', {"sid": this.sid});
	}

	async getTournamentResultsInProgress() {
		dlog("getTournamentResultsInProgress");
		this.conn.emit('get_tournament_results_in_progress', {"sid": this.sid});
	}

	async getPlayerTournamentsHistory() {
		dlog("getPlayerTournamentsHistory");
		this.conn.emit('get_player_tournaments_history', {"sid": this.sid});
	}

	async tournamentPersonalizedResultsHistory(data) {
		dlog(`TBI tournamentPersonalizedResultsHistory, got ${JSON.stringify(data)}`)
	}

	async getPersonalizedTournamentResultsHistory(tournamentId) {
		dlog("getPersonalizedTournamentResultsHistory");
		this.conn.emit('get_personalized_tournament_results_history', {"sid": this.sid, "tournament_id": tournamentId});
	}

	async placeBet(amount, betButtonId, isAutoBetTicket, advancedPlay = '', useBetInsurance = false) {
		dlog("placeBet")
		this.lastCashoutTsPerTicketId = {}
		if (betButtonId in this.lastBetTsPerButtonId && Date.now() - this.lastBetTsPerButtonId[betButtonId] < 900) {
			dlog("BLOCKED BET SPAM (SAME REQUEST SENT WITHIN LAST 900ms)")
			return
		}
		if (this.waitingBetAckButtonIds.includes(betButtonId)){
			dlog("BLOCKED DOUBLE SPENDING (WAITING FOR PREVIOUS BET ACK)")
			return
		}
  		this.waitingBetAckButtonIds.push(betButtonId); 
		this.lastBetTsPerButtonId[betButtonId] = Date.now()
  		this.conn.emit('placed_bet', {"sid": this.sid, "bet_amount": amount, "bet_button_id": betButtonId, 
				"is_autobet": isAutoBetTicket, "advanced_play_params": advancedPlay, insurance_enabled: useBetInsurance})
	}

	async cancelBet(ticketId) {
		dlog("cancelBet")
  		this.conn.emit('canceled_bet', {"sid": this.sid, "ticket_id": ticketId})
	}

	async cashout(ticketId, mp, is_autocashout){
		dlog("cashout")
		this.lastBetTsPerButtonId = {}
		if (ticketId in this.lastCashoutTsPerTicketId && Date.now() - this.lastCashoutTsPerTicketId[ticketId] < 300) {
			dlog("BLOCKED CASHOUT SPAM (SAME REQUEST SENT WITHIN LAST 300ms)")
			return
		}
		this.lastCashoutTsPerTicketId[ticketId] = Date.now()
  		this.conn.emit('cashout', {"sid": this.sid, "mp": mp, "ticket_id": ticketId, "is_autocashout": is_autocashout})
	}

	async playFreeTickets(){
		dlog("playFreeTickets")
  		this.conn.emit('play_free_tickets', {"sid": this.sid})
	}

	async requestJackpotData(company_id=1){
		if (this.sid != null){
			dlog("requestJackpotData")
  			this.conn.emit('jackpot_data', {"sid": this.sid, "company_id":company_id})
		}
	}

	async requestPlayerBonusHistory(){
		if (this.sid != null){
			dlog("requestPlayerBonusHistory")
  			this.conn.emit('player_bonus_history', {"sid": this.sid})
		}
	}

	async requestUserBalance(){
		if (this.sid != null){
			dlog("requestUserBalance")
	  		this.conn.emit('get_user_balance', {"sid": this.sid})
	  	}
	}

	async requestJackpotHistory(level){
		dlog("requestJackpotHistory")
  		this.conn.emit('jackpot_history', {"sid": this.sid, "level":level})
	}

	async msgNotSent(data){
		dlog(`TBI msgNotSent, got ${JSON.stringify(data)}`)
	}

	async requestRoundHash(round_id=-1, ticket_id=-1){
		dlog("requestRoundHash")
  		this.conn.emit('round_hash', {"sid": this.sid, "round_id":round_id, "ticket_id":ticket_id})
	}

	async requestRoundMpByHash(round_hash=""){
		dlog("requestRoundMpByHash")
  		this.conn.emit('mp_from_hash', {"sid": this.sid, "round_hash":round_hash})
	}

	async requestTicketDetails(ticket_id=""){
		dlog("requestTicketDetails")
  		this.conn.emit('ticket_details', {"sid": this.sid, "ticket_id":ticket_id})
	}
	
	async incomingOfferMeteorShower() {
		dlog(`incomingOfferMeteorShower`)
	}

	async offerMeteorShower(amount,quantity) {
		dlog("requestofferMeteorShower")
		this.conn.emit('offer_meteor_rain', {"sid": this.sid, "quantity":quantity, "amount":amount})
	}

	async claimMeteorTicket(ticketId) {
		dlog("claimMeteorTicket");
		this.conn.emit('claim_meteor_ticket', {"sid": this.sid, "ticketid":ticketId})
	}

	async requestUpdateSettings(sound=false, music=false){
		dlog("requestUpdateSettings")
  		this.conn.emit('player_settings', {"sid": this.sid, "sound":sound, "music":music})
	}

	async requestSetAvatarId(new_avatar_id){
		dlog("requestSetAvatarId")
  		this.conn.emit('avatar_id', {"sid": this.sid, "avatar_id":new_avatar_id})
	}

	async requestTopWinsWeekly(){
		dlog("requestTopWinsWeekly")
  		this.conn.emit('bet_wins', {"sid": this.sid, "top_wins":{"days_back":7}})
	}

	async requestTopWinsMonthly(){
		dlog("requestTopWinsMonthly")
  		this.conn.emit('bet_wins', {"sid": this.sid, "top_wins":{"days_back":30}})
	}

	async requestTopWinsYearly(){
		dlog("requestTopWinsYearly")
  		this.conn.emit('bet_wins', {"sid": this.sid, "top_wins":{"days_back":365}})
	}

	async requestTopOddsWeekly(){
		dlog("requestTopOddsWeekly")
  		this.conn.emit('bet_wins', {"sid": this.sid, "top_odds":{"days_back":7}})
	}

	async requestTopOddsMonthly(){
		dlog("requestTopOddsMonthly")
  		this.conn.emit('bet_wins', {"sid": this.sid, "top_odds":{"days_back":30}})
	}

	async requestTopOddsYearly(){
		dlog("requestTopOddsYearly")
  		this.conn.emit('bet_wins', {"sid": this.sid, "top_odds":{"days_back":365}})
	}

	async requestBiggestOddsWeekly(){
		dlog("requestBiggestOddsWeekly")
  		this.conn.emit('bet_wins', {"sid": this.sid, "biggest_odds":{"days_back":7}})
	}

	async requestBiggestOddsMonthly(){
		dlog("requestBiggestOddsMonthly")
  		this.conn.emit('bet_wins', {"sid": this.sid, "biggest_odds":{"days_back":30}})
	}

	async requestBiggestOddsYearly(){
		dlog("requestBiggestOddsYearly")
  		this.conn.emit('bet_wins', {"sid": this.sid, "biggest_odds":{"days_back":365}})
	}

	// TBI HANDLERS

  	async connected(){
		dlog(`TBI connected`)
  	}

	async exception(data){
		dlog(`TBI exception, got ${JSON.stringify(data)}`)
		alert(JSON.stringify(data['desc']))
	}

	async notification(data){
		dlog(`TBI notification, got ${JSON.stringify(data)}`)
	}

	async login(data){
		dlog(`TBI login, got ${JSON.stringify(data)}`)
	}

	async userBalance(data){
		dlog(`TBI userBalance, got ${JSON.stringify(data)}`)
	}

	async playedFreeTickets(data){
		dlog(`TBI playedFreeTickets, got ${JSON.stringify(data)}`)
	}

	async claimMeteorTicketAck(data) {
		dlog(`TBI claimMeteorTicketAck, got ${JSON.stringify(data)}`)
	}

	async sessionStatus(data){
		dlog(`TBI sessionStatus, got ${JSON.stringify(data)}`)
	}

	async betAck(data){
		dlog(`TBI betAck, got ${JSON.stringify(data)}`)
	}

	async betCancelAck(data){
		dlog(`TBI betCancelAck, got ${JSON.stringify(data)}`)
	}

	async cashoutAck(data){
		dlog(`TBI cashoutAck, got ${JSON.stringify(data)}`)
	}

	async gameStateUpdate(data){
		dlog(`TBI gameStateUpdate, got ${JSON.stringify(data)}`)
	}

	async retailGameStateUpdate(data){
		dlog(`TBI retailGameStateUpdate, got ${JSON.stringify(data)}`)
	}

	async tickBets(data){
		dlog(`TBI tickBets, got ${JSON.stringify(data)}`)
	}

	async tickCanceledBets(data){
		dlog(`TBI tickCanceledBets, got ${JSON.stringify(data)}`)
	}

	async tickCashouts(data){
		dlog(`TBI tickCashouts, got ${JSON.stringify(data)}`)
	}

	async retailTickets(data) {
		dlog(`TBI retailTickets, got ${JSON.stringify(data)}`)
	}

	async lostConn(){
		dlog(`TBI lostConn`)
	}

	async totalOnline(data){
		dlog(`TBI totalOnline ${JSON.stringify(data)}`)
	}

	async jackpotData(data){
		dlog(`TBI jackpotData ${JSON.stringify(data)}`)
	}

	async playerBonusHistory(data){
		dlog(`TBI playerBonusHistory ${JSON.stringify(data)}`)
	}

	async jackpotHistory(data){
		dlog(`TBI jackpotHistory ${JSON.stringify(data)}`)
	}

	async roundMpByHash(data){
		dlog(`TBI roundMpByHash ${JSON.stringify(data)}`)
	}

	async ticketDetails(data){
		dlog(`TBI ticketDetails ${JSON.stringify(data)}`)
	}

	async roundHashData(data){
		dlog(`TBI roundHashData ${JSON.stringify(data)}`)
	}

	async chatUpdate(data){
		dlog(`TBI chatUpdate ${JSON.stringify(data)}`)
	}

	async newChatMessage(data){
		dlog(`TBI newChatMessage ${JSON.stringify(data)}`)
	}

	async topWinsWeekly(data){
		dlog(`TBI topWinsWeekly, got ${JSON.stringify(data)}`)
	}

	async topWinsMonthly(data){
		dlog(`TBI topWinsMonthly, got ${JSON.stringify(data)}`)
	}

	async topWinsYearly(data){
		dlog(`TBI topWinsYearly, got ${JSON.stringify(data)}`)
	}

	async topOddsWeekly(data){
		dlog(`TBI topOddsWeekly, got ${JSON.stringify(data)}`)
	}

	async topOddsMonthly(data){
		dlog(`TBI topOddsMonthly, got ${JSON.stringify(data)}`)
	}

	async topOddsYearly(data){
		dlog(`TBI topOddsYearly, got ${JSON.stringify(data)}`)
	}

	async biggestOddsWeekly(data){
		dlog(`TBI biggestOddsWeekly, got ${JSON.stringify(data)}`)
	}

	async biggestOddsMonthly(data){
		dlog(`TBI biggestOddsMonthly, got ${JSON.stringify(data)}`)
	}

	async biggestOddsYearly(data){
		dlog(`TBI biggestOddsYearly, got ${JSON.stringify(data)}`)
	}
	async highScoresRound(data){
		dlog(`TBI highScoresRound, got ${JSON.stringify(data)}`)
	}

}

export default TransportAbstract;
