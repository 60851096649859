import {Howl} from 'howler';
import rocketmanTransport from '../RocketmanTransport';

var VOLUME = 0.15;

const urlParams = new URLSearchParams(window.location.search);
const region = urlParams.get("region");

class MusicPlayer {
  constructor() {
    if (MusicPlayer.instance == null) { 
      this.enabled = false;
      if (region == 'retail') return
      this.sound_files = {
        rocketman_ingame_theme : new Howl({
            src: ['Music/rocketman_ingame_theme.mp3'],
            volume: VOLUME
        })
      };
      this.initUserInteraction();
      MusicPlayer.instance = this;
    }
    
    return MusicPlayer.instance;
  }

  initUserInteraction() {
    const enableSounds = () => {
      // Attempt to play a sound or initialize sound objects to unblock audio playback
      if (rocketmanTransport.music) {
        this.sound_files.rocketman_ingame_theme.loop();
        this.sound_files.rocketman_ingame_theme.play();
      }
     
      this.enabled = true;
      // Remove the interaction listeners after the first interaction
      document.removeEventListener('click', enableSounds);
      document.removeEventListener('keydown', enableSounds);
      document.removeEventListener('touchstart', enableSounds);
    };

    // Add event listeners for the first interaction
    document.addEventListener('click', enableSounds);
    document.addEventListener('keydown', enableSounds);
    document.addEventListener('touchstart', enableSounds);
  }

  /**
   * Plays the filename from SFX folder without .mp3 extension
   * 
   * @param {string} soundName 
   */
  play(soundName) {
    // Only play if not currently playing
    if (!this.sound_files[soundName].playing() && this.enabled) {
      this.sound_files[soundName].play();
    }
  }

  /**
   * Stops the filename from SFX folder without .mp3 extension
   * 
   * @param {string} soundName 
   */
  stop(soundName) {
    this.sound_files[soundName].stop();
  }

  /**
   * Sets the volume for given sound
   * 
   * @param {string} soundName 
   * @param {number} level 
   */
  volume(soundName, level) {
    this.sound_files[soundName].volume(level);
  }

  /**
   * Sets the volume for given sound
   * 
   * @param {string} soundName 
   * @param {boolean} level 
   */
   loop(soundName, val) {
    this.sound_files[soundName].loop(val);
  }

  state(soundName) {
    if (region == 'retail') return
    return this.sound_files[soundName].state();
  }

  /**
   * Fades sound, from values (0-1 or decimal), to alues (0-1 or decimal), duration miliseconds
   * 
   * @param {string} soundName 
   * @param {number} from 
   * @param {number} to 
   * @param {number} duration 
   */
  fade(soundName, from, to, duration) {
    this.sound_files[soundName].fade(from, to, duration);
  }

  autoPlay(soundName, val) {
    this.sound_files[soundName].on('load',this.play(soundName));
  }

}

const musicPlayer = new MusicPlayer();
export default musicPlayer;
