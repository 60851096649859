import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import soundFxPlayer from "../services/Audio/SoundFxPlayer";

const HeaderDialogWarningChat = ({ msg, className }) => {
  const OFFSET = 80;

  return (
    <ScrollAnimation
      animateOnce
      offset={OFFSET}
      delay={200}
      duration={1}
      animateIn="fadeInDown"
      animateOut="fadeInUp"
    >
      <div className={"cashout-header-dialog warning " + className}>
        <div>{msg}</div>
        <button
          className="cashout-header-dialog-button close-container bet-info-close"
          onClick={() => {
            soundFxPlayer.play("you_have_cashed_out_close");
          }}
        >
          <div className="leftright"></div>
          <div className="rightleft"></div>
        </button>
      </div>
    </ScrollAnimation>
  );
};

export default HeaderDialogWarningChat;
