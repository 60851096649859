import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useHistory } from "react-router-dom";
import rocketmanTransport from "../../services/RocketmanTransport";
import DOMPurify from "dompurify";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";
import translate from "../../services/Translation/lang";
import provablyFairFormatter from "../../services/ProvablyFairAlgo";
import PrimaryButton from "../PrimaryButton";

export default function BetVerifyContent(props) {
  const location = useLocation();
  const [roundHash, setRoundHash] = useState("");
  const [showAlgoTable, setShowAlgoTable] = useState(false);
  const { width } = useWindowDimensions();
  const stateParams = new URLSearchParams(location.search);
  const roundId = stateParams.get("ticketRid") || props?.ticketRid;
  const ticketTime = stateParams.get("ticketTime") || props?.ticketTime;
  const [initialRoundHash, setInitialRoundHash] = useState("");
  const [provablyFairalgo, setProvablyFairalgo] = useState({});
  const [rtps, setRtp] = useState('');


  useEffect(() => {
    if (roundId) {
      rocketmanTransport.requestRoundHash(roundId);
    }
  }, [roundId, props?.tikcetRid]);

  window.setRoundHash = (val) => {
    const hash = val.hash;
    const rtp = val.rtp;
    setRtp(rtp);
    if (hash.slice(0, 2) === "0x") {
      setRoundHash(hash.slice(2, hash.length));
      setInitialRoundHash(hash.slice(2, hash.length));
      setProvablyFairalgo(
        provablyFairFormatter(
          hash.slice(2, hash.length),
          rtp
        )
      );
    } else {
      setProvablyFairalgo(
        provablyFairFormatter(hash, rtp)
      );
      setInitialRoundHash(hash);
    }
  };

  const setNewRoundHash = (val) => {
    if (val.slice(0, 2) === "0x") {
      setRoundHash(val.slice(2, val.length));
      setProvablyFairalgo(
        provablyFairFormatter(
          val.slice(2, val.length),
          rtps
        )
      );
    } else {
      setProvablyFairalgo(
        provablyFairFormatter(val, rtps)
      );
      setRoundHash(val);
    }
    setInitialRoundHash(val);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");
  const history = useHistory();

  const handleBetVerify = () => {
    if (version === "desktop" && width >= 1024) {
      window.setBetVerifyActive(false);
    } else {
      history.goBack();
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          handleBetVerify()
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const highLight = (mp) => {
    if (Number(mp) > 2 && Number(mp) <= 9.99) return "win";
    if (Number(mp) > 9.99 && Number(mp) <= 99.99) return "win-big";
    if (Number(mp) > 99.99) return "win-legendary";

    return "win-small";
  };

  return (
    Object.keys(provablyFairalgo).length && (
      <div className="page-holder" ref={wrapperRef}>
        <div className="container">
          {roundHash === initialRoundHash ? (
            <div className="bet-info-header">
              <div className="bet-info-header-left">
                <h4>
                  {translate("round")} {roundId}
                </h4>
              </div>
              <div className="bet-info-header-right">
                <p className="bet-info-time">{ticketTime}</p>
                <button
                  className="close-container bet-info-close"
                  // onClick={() => window.setBetInfoActive(false)}
                  onClick={handleBetVerify}
                >
                  <div className="leftright"></div>
                  <div className="rightleft"></div>
                </button>
              </div>
            </div>
          ) : (
            <div className="bet-info-header justify-end">
              <div className="bet-info-header-right">
                <button
                  className="close-container bet-info-close"
                  // onClick={() => window.setBetInfoActive(false)}
                  onClick={handleBetVerify}
                >
                  <div className="leftright"></div>
                  <div className="rightleft"></div>
                </button>
              </div>
            </div>
          )}

          <SimpleBar className="desktop-bet-info">
            <p className="bet-verify-content-text">
              {translate("randomly_generated_server_hash")}
            </p>
            <input
              className="bet-info-hash-input"
              //placeholder="53e2c150ebc6348f7db49720006d142b7e613f7696f034aa82f1fa4f301ed5ba3946f33fe59134e78feb62747a72a1d9e1a562c86465fb3aa5558fe6d90f6114"
              onChange={(event) => {
                setNewRoundHash(DOMPurify.sanitize(event.target.value));
              }}
              value={String(roundHash)}
            />
            {/* <div className="bet-info-hash-input text-center">
            <p
              contentEditable="true"
              onInputCapture={(e) => { setNewRoundHash(String(DOMPurify.sanitize(e.target.innerText))) }}
              suppressContentEditableWarning={true}
            >
              <span style={{color: 'red'}}>{roundHash.slice(0,13)}</span>{roundHash.slice(14,roundHash.length -1 )}
            </p>
            </div> */}
            <p className="bet-verify-content-text">
              {translate("convert_first_13_to_decimal")}
            </p>
            <p className="bet-verify-content-text">
              {translate("hex")} ({" "}
              {provablyFairalgo.trimmedForExport
                .split("")
                .map((s) => s)
                .join(",")}{" "}
              ) = <b>{parseInt(provablyFairalgo.trimmedForExport, 16)}</b>
            </p>
            <i
              onClick={() => setShowAlgoTable(!showAlgoTable)}
              className="bet-verify-algo-table-toggle"
            >
              {showAlgoTable
                ? translate("hide_hex_to_decimal")
                : translate("show_hex_to_deimal")}{" "}
            </i>
            {showAlgoTable ? (
              <div className="bet-verify-algo-table">
                <div className="bet-verify-algo-holder">
                  <p className="bet-verify-algo-row">
                    &nbsp;
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[0]}
                    </span>{" "}
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[0]} * 16
                      <sup>12</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[1]}
                    </span>{" "}
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[1]} * 16
                      <sup>11</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[2]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[2]} * 16
                      <sup>10</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[3]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[3]} * 16
                      <sup>9</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[4]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[4]} * 16
                      <sup>8</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[5]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[5]} * 16
                      <sup>7</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[6]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[6]} * 16
                      <sup>6</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[7]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[7]} * 16
                      <sup>5</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[8]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[8]} * 16
                      <sup>4</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[9]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[9]} * 16
                      <sup>3</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[10]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[10]} * 16<sup>2</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[11]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[11]} * 16<sup>1</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    +
                    <span className="bet-verify-algo-col">
                      {provablyFairalgo.dec[12]}
                    </span>
                    <span className="bet-verify-algo-col">
                      ({provablyFairalgo.hashArray[12]} * 16<sup>0</sup>)
                    </span>
                  </p>
                  <p className="bet-verify-algo-row">
                    =
                    <span className="bet-verify-algo-col">
                      {parseInt(provablyFairalgo.trimmedForExport, 16)}
                    </span>
                  </p>
                </div>
                <div className="bet-verify-table">
                  <div className="bvt-table">
                    <span>A = 10</span>
                    <span>B = 11</span>
                    <span>C = 12</span>
                    <span>D = 13</span>
                    <span>E = 14</span>
                    <span>F = 15</span>
                  </div>
                </div>
              </div>
            ) : null}
            <p className="bet-verify-content-text mt-3">
              {translate("calculate_raw_mp_to_decimal")}
            </p>
            <div className="bet-verify-content-text">
              {/* {translate('raw_multiplier')} = ( 99 / ( 1 -{" "}
              {parseInt(provablyFairalgo.trimmedForExport, 16)} / 2<sup>52</sup>
              )) = {parseFloat(Math.floor(provablyFairalgo.x)).toFixed()} */}
              <div className="raw-multiplier-holder">
                {translate("raw_multiplier")} = &nbsp;
                <div className="inside-brackets">
                  <div className="fraction">
                    <div className="numerator">
                      <span>2<sup>52</sup> - 1</span>
                    </div>
                    <div className="bracket-divider"></div>
                    <div className="denominator">
                      {parseInt(provablyFairalgo.trimmedForExport, 16)}
                    </div>
                  </div>
                </div>
                &nbsp;= {provablyFairalgo.rawMultiplier}
              </div>
              <p className="bet-verify-content-text mt-4">
              {translate("raw_multiplier_to_edged")}{" "}
              {rtps}% {translate("rtp")}:
              </p>
              <div className="raw-multiplier-holder">
                {translate("multiplier")} =
                <div className="bracket-floor-open"></div>
                <div className="inside-brackets">
                    <div className="numerator">
                      <span>{provablyFairalgo.rawMultiplier} * {rtps}</span>
                    </div>
                      
                </div>
                <div className="bracket-floor-close"></div>
                / 100
                = <span
                  className={[
                    "bet-info-multiplier mmsb-info-content ml-2",
                    highLight(provablyFairalgo.multiplier.toFixed(2)),
                  ].join(" ")}
                >
                  {provablyFairalgo.multiplier > 20000
                    ? "20000.00"
                    : provablyFairalgo.multiplier.toFixed(2)}
                </span>
              </div>

            </div>
            <PrimaryButton
              title={translate("close")}
              onClick={handleBetVerify}
            ></PrimaryButton>
          </SimpleBar>
        </div>
      </div>
    )
  );
}
