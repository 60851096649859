import React, { useEffect, useMemo, useRef, useState } from "react";
import Switch from "react-switch";
import OdometerComp from "../OdometerComp";
import rocketmanTransport from "../../services/RocketmanTransport";
import AutoCashout from "../BetComponent/AutoCashout";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
import translate from "../../services/Translation/lang";
import AdvancedAutoPlay from "../pop-ups/AdvancedAutoPlay";
import { commaToDot } from "../../services/Helpers/CommaToDot";
import Currency from "../Currency/Currency";
import { NumericFormat } from 'react-number-format';
import { currencyFormatter } from "../../services/Helpers/NumberFormatter";
import storage from "../../services/Storage/Storage";
import { clearDotsAndCommas } from "../../services/Helpers/StringHelper";
import ProvablyFairSvg from "../svg/provably-fair-svg";

const INPUT_REGX = /^(20000|[0-9]{1,5}([.,]\d{0,2})?|0([.,]0{1,2})?)$/;

const MAX_CASHOUT_VAL = 20000;
const MIN_CASHOUT_VAL = 1.01;
const BUY_DISABLE_TIMEOUT = 1000;

export default function Button1(props) {
  const [max, setMax] = useState(100);
  const [min, setMin] = useState(0);
  const [predefinedBets, setPredefinedBets] = useState(
    rocketmanTransport.predefinedBets
  );
  const [fixedBet, setFixedBet] = useState(rocketmanTransport.fixedBet);
  const [disabled, setDisabled] = useState(false);
  const [dissalowBets, setDissalowBets] = useState(false);
  const [showAdvancedAutoPlay, setShowAdvancedAutoPlay] = useState(false);
  const [button1Ballance, setButton1Balance] = useState(0);
  const [disabledCashout, setDisabledCashout] = useState(false);
  const [balanceDiffRerender,setForceBallanceDiffRerender] = useState(false);
  const [useBetInsurance, setBetInsurance] = useState(storage.get('use-bet-insurance-1')|| 0);
  /* const autoBet = Boolean(rocketmanTransport.launcherAutoBet);
  const autoCashout = rocketmanTransport.launcherAutCashout; */

  const buttonRefs = useRef([]);

  const handleClick = (predefinedBet, index) => {
    setButton1Params((prevState) => ({
      ...prevState,
      val: predefinedBet,
    }));
    soundFxPlayer.play("increase_amount_BTN");

    // Scroll to the clicked button
    buttonRefs.current[index].scrollIntoView({
      behavior: "smooth",
      inline: "center", // aligns button to the center of horizontal scroll
      block: "nearest", // ensures horizontal scroll behavior
    });
  };

  const [button1Params, setButton1Params] = useState({
    autoBet: false,
    autoCashout: "",
    ticketId: null,
    roundId: null,
    val: (storage.get('lastBetValue1') && storage.get('lastBetCurrency') === rocketmanTransport.myCurrency) ? storage.get('lastBetValue1') : rocketmanTransport.defBet.toFixed(rocketmanTransport.noOfDecimals),
    minCashoutMultiplier: "",
    buttonBusy: false,
    gameState: "initialState",
  });

  var disableBuy;

  window.setMax1 = setMax;
  window.setMin1 = setMin;
  window.setPredefinedBets1 = setPredefinedBets;
  window.setFixedBet1 = setFixedBet;
  window.toggleButton1AutoBet = (val) => {
    setButton1Params((prevState) => ({
      ...prevState,
      autoBet: val,
    }));
  };

  useEffect(() => {
    storage.set('use-bet-insurance-1', Number(useBetInsurance))
  },[useBetInsurance]);

  useEffect(() => {
    setMax(rocketmanTransport.maxBet);
    setMin(rocketmanTransport.minBet);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(disableBuy);
    };
  }, [disableBuy]);

  const buttonValRef = useRef();
  const autoCashoutRef = useRef();

  window.setDissalowBets1 = setDissalowBets;
  window.setButton1Balance = setButton1Balance;

  window.setButton1MaxBetVal = (val) => {
    setButton1Params((prevState) => ({
    ...prevState,
    val
  }))};

  // On b key pressed try to buy
  window.betFirstButton = () => {
    if (!button1Params.buttonBusy) {
      rocketmanTransport.bet(
        button1Params.val,
        button1Params.autoCashout,
        button1Params.autoBet,
        1
      );
    }
  };

  window.firstButtonCancelOrCashout = () => {
    if (button1Params.buttonBusy) {
      if (button1Params.gameState === "betActive") {
        handleCancelBet(button1Params.ticketId, 1);
      } else {
        rocketmanTransport.cashoutBet(
          button1Params.autoCashout ? button1Params.autoCashout : false,
          1,
          button1Params.ticketId,
          button1Params.autoBet
        );
      }
    }
  };

  window.forceButton1BallanceDiffRerender = () => {
    setForceBallanceDiffRerender(!balanceDiffRerender);
  }

  const handleChangeAutoBet = (nextChecked, off = false) => {
    soundFxPlayer.play("menu_switch_1");
    if (rocketmanTransport.buttons[1]) {
      rocketmanTransport.buttons[1].autoBet = off;
      rocketmanTransport.toggleAutoBet(
        1,
        button1Params.ticketId,
        button1Params.autoBet
      );
    }
    setButton1Params((prevState) => ({
      ...prevState,
      autoBet: off,
    }));
  };

  window.handleCHangeAutoBet1 = handleChangeAutoBet;
  window.setButton1Params = (data) => {
    setButton1Params(data);
  };

  const onValueBlur = (e) => {
    // if (Number(e.target.value) > max) e.target.value = max;
    // //if (Number(e.target.value) < min) e.target.value = min;
    // setButton1Params((prevState) => ({
    //   ...prevState,
    //   val: e.target.value,
    // }));
  };

  const placeBet = (advanced = false) => {
    if(button1Params.val >= min && button1Params.val <= max && button1Params.val <= rocketmanTransport.balance) {
      storage.set('lastBetValue1', button1Params.val)
      storage.set('lastBetCurrency', rocketmanTransport.myCurrency)
    }
    setDisabled(true);
    disableBuy = setTimeout(() => {
      setDisabled(false);
      clearTimeout(disableBuy);
    }, BUY_DISABLE_TIMEOUT);

    rocketmanTransport.bet(
      button1Params.val,
      button1Params.autoCashout,
      button1Params.autoBet,
      1,
      advanced
    );
  };

  const betInputRef1 = useRef(null);

const handleValueChange = (values, sourceInfo) => {
      const { floatValue } = values;

      if (floatValue > max) {
        setButton1Params((prevState) => ({
          ...prevState,
          val: max,
        }));
      } else {
        setButton1Params((prevState) => ({
          ...prevState,
          val: floatValue,
        }));
      }
  }

  const handleIsAllowed = (values) => {

    const { floatValue } = values;

    if (floatValue < 1) {
         setButton1Params((prevState) => ({
        ...prevState,
        val: 1,
      }));
    } else if (floatValue > max) {
         setButton1Params((prevState) => ({
        ...prevState,
        val: max,
      }));
    } 

    return floatValue >= 1 && floatValue <= max;
  }
  
  

  const handleCancelBet = (ticketId, buttonIndex) => {
    rocketmanTransport.cancelTicket(ticketId, buttonIndex, button1Params.val);
  };

  const increase = () => {
    if (fixedBet.length > 0) {
      const currentValue = Number(button1Params.val);
  
      let nextHigherValue = fixedBet[fixedBet.length - 1];
      for (let i = fixedBet.length - 1; i >= 0; i--) {
        if (currentValue < fixedBet[i]) {
          nextHigherValue = fixedBet[i];
        } else {
          break;
        }
      }
  
      soundFxPlayer.play("increase_amount_BTN");
      setButton1Params((prevState) => ({
        ...prevState,
        val: nextHigherValue.toFixed(rocketmanTransport.noOfDecimals),
      }));
    } else {
      if (Number(button1Params.val) + Number(rocketmanTransport.defBet) <= max) {
        soundFxPlayer.play("increase_amount_BTN");
        setButton1Params((prevState) => ({
          ...prevState,
          val: (
            Number(prevState.val) + Number(rocketmanTransport.defBet)
          ).toFixed(rocketmanTransport.noOfDecimals),
        }));
      }
    }
  };
  
  const decrease = () => {
    if (fixedBet.length > 0) {
      const currentValue = Number(button1Params.val);
  
      let nextLowerValue = fixedBet[0];
      for (let i = 0; i < fixedBet.length; i++) {
        if (currentValue > fixedBet[i]) {
          nextLowerValue = fixedBet[i];
        } else {
          break;
        }
      }
  
      soundFxPlayer.play("decrease_amount_BTN");
      setButton1Params((prevState) => ({
        ...prevState,
        val: nextLowerValue.toFixed(rocketmanTransport.noOfDecimals),
      }));
    } else {
      if (Number(button1Params.val) - Number(rocketmanTransport.defBet) >= min) {
        soundFxPlayer.play("decrease_amount_BTN");
        setButton1Params((prevState) => ({
          ...prevState,
          val: (
            Number(prevState.val) - Number(rocketmanTransport.defBet)
          ).toFixed(rocketmanTransport.noOfDecimals),
        }));
      } else {
        soundFxPlayer.play("decrease_amount_BTN");
        setButton1Params((prevState) => ({
          ...prevState,
          val: min.toFixed(rocketmanTransport.noOfDecimals),
        }));
      }
    }
  };

  const checkMinAutoCashout = () => {
    if (Number(button1Params.autoCashout) === 1) {
      if (rocketmanTransport.buttons[1]) {
        rocketmanTransport.buttons[1].autoCashout = MIN_CASHOUT_VAL;
      }
      setButton1Params((prevState) => ({
        ...prevState,
        autoCashout: MIN_CASHOUT_VAL,
      }));
    }
  };

  const setAutoCashValue = (val) => {
    if (val) {
      if (INPUT_REGX.test(val)) {
        const convertedVal = commaToDot(val);
        if (Number(convertedVal) > MAX_CASHOUT_VAL) return;
        if (Number(convertedVal) < 1) return;
        if (rocketmanTransport.buttons[1]) {
          rocketmanTransport.buttons[1].autoCashout = convertedVal;
        }
        setButton1Params((prevState) => ({
          ...prevState,
          autoCashout: convertedVal,
        }));
        soundFxPlayer.play("set_auto_cashout");
      } else {
        autoCashoutRef.current.blur();
      }
    } else {
      if (rocketmanTransport.buttons[1]) {
        rocketmanTransport.buttons[1].autoCashout = "";
      }
      setButton1Params((prevState) => ({
        ...prevState,
        autoCashout: "",
      }));
      soundFxPlayer.play("set_auto_click_sound");
    }
  };

  const handleInProgressTurnOffAdvancedAutoPlay = () => {
    rocketmanTransport.setAdvancedAutoPlay(1,false,button1Params.val);
    handleChangeAutoBet(null, false);
  }

  const handleInBetActiveTurnOffAdvancedAutoPlay = () => {
    rocketmanTransport.setAdvancedAutoPlay(1,false,button1Params.val);
    handleCancelBet(button1Params.ticketId, 1);
  }

  return (
    <div
      className={
        "play-now-component button-1 " +
        (!props.isActive ? "button-1-hide" : "")
      }
    >
      {rocketmanTransport.advancedAutoplayInProgress[1].inProgress ? (
        <p className="advanced-autoplay-text">
          {translate("autoplay")}{" "}
          <span>{rocketmanTransport.advancedRoundsPlayed[1].played}</span>{" "}
          {translate("rounds")}{" "}
          <span>
            {rocketmanTransport.advancedBalanceDiff[1].balance.toFixed(
              rocketmanTransport.noOfDecimals
            )}
          </span>{" "}
          <Currency>{rocketmanTransport.myCurrency}</Currency>
        </p>
      ) : null}
      {useMemo(() => (
        <AdvancedAutoPlay
          show={showAdvancedAutoPlay}
          setShowAdvancedAutoPlay={setShowAdvancedAutoPlay}
          placeBet={placeBet}
          handleCancelBet={handleCancelBet}
          decrease={decrease}
          increase={increase}
          onValueChange={handleValueChange}
          isAllowed={handleIsAllowed}
          handleChangeAutoBet={handleChangeAutoBet}
          onValueBlur={onValueBlur}
          buttonParams={button1Params}
          buttonIndex={1}
          currRound={props.currRound}
          setAutoCashValue={setAutoCashValue}
          //autoCashoutRef={autoCashoutRef}
          checkMinAutoCashout={checkMinAutoCashout}
          button0Ballance={button1Ballance}
          setDisabledCashout={setDisabledCashout}
        />
      ),[showAdvancedAutoPlay,button1Params,button1Ballance,props.currRound])}
      <div className={"play-now-top " + (props.isActive ? "active" : "")}>
        <div
          className={
            "play-now-number-input " +
            (button1Params.gameState === "betInProgress" ? "in-progress" : "") + (clearDotsAndCommas(betInputRef1?.current?.value)?.length >= 7 ? " zoom-out" : " zoom-in")
          }
        >
          <button
            disabled={
              button1Params.gameState === "betActive" ||
              rocketmanTransport.advancedAutoplayInProgress[1].inProgress
                ? true
                : false
            }
            onClick={
              button1Params.gameState === "betActive" ? () => null : decrease
            }
            className="play-now-number-input-decrese"
          >
            <div className="minus"></div>
          </button>
          <NumericFormat disabled={button1Params.gameState === "betActive" || fixedBet.length > 0}
            valueIsNumericString={true}
            value={button1Params.val}
            thousandSeparator=","
            decimalSeparator="."
            getInputRef={(el) => (betInputRef1.current = el)}
            allowNegative={false}
            decimalScale={rocketmanTransport.noOfDecimals ?? 2}
            onValueChange={handleValueChange}
            isAllowed={handleIsAllowed}
            // fixedDecimalScale={rocketmanTransport.noOfDecimals > 0 ? true : false}
            />
          <button
            disabled={
              button1Params.gameState === "betActive" ||
              rocketmanTransport.advancedAutoplayInProgress[1].inProgress
                ? true
                : false
            }
            onClick={
              button1Params.gameState === "betActive" ? () => null : increase
            }
            className="play-now-number-input-increse"
          >
            <div className="plus"></div>
          </button>
        </div>

        <button
          disabled={
            (button1Params.gameState === "betActive" &&
              button1Params?.ticketType === 3) ||
            (button1Params.gameState === "betActive" &&
              Number(button1Params?.ticketType) === 2) ||
            (button1Params.gameState === "initialState" && disabled) ||
            (button1Params.gameState === "initialState" && dissalowBets) || 
              disabledCashout || ((button1Params.gameState === "betActive" && rocketmanTransport.cancelButtonDisabled === true))
          }
          className={
            "bet-button " +
            (button1Params.gameState === "betActive"
              ? "bet-button-cancel"
              : button1Params.gameState === "betInProgress"
              ? "bet-button-in-progress"
              : "")
          }
          onClick={() =>
            !button1Params.buttonBusy
              ? placeBet()
              : button1Params.gameState === "betActive"
              ? handleCancelBet(button1Params.ticketId, 1)
              : rocketmanTransport.cashoutBet(
                  button1Params.autoCashout ? button1Params.autoCashout : false,
                  1,
                  button1Params.ticketId,
                  button1Params.autoBet
                )
          }
        >
          {button1Params.gameState === "betActive" ? (
            <span>{translate("cancel")}</span>
          ) : button1Params.gameState === "betInProgress" ? (
            <div className="cashout-button-holder">
               <span>{translate('cashout')}{" "}</span>&nbsp;{button1Params.minCashoutMultiplier > 0 ? "@ " + button1Params.minCashoutMultiplier : null}
              <span className="cashout-button-odometer">
                +<OdometerComp betAmount={button1Params.val} />{" "}
                <Currency className="odometer-currency ">{rocketmanTransport.myCurrency}</Currency>
              </span>
            </div>
          ) : (
            <span>{translate("bet")}</span>
          )}
          {
            button1Params?.betInsurance && button1Params.ticketType == 1 ? (
            <div className="bet-insurance-protected-wrapper">
              <div className="bip-holder"><ProvablyFairSvg width={14} height={17}  /></div>
            </div>) : null
          }
        </button>
      </div>
      {
        rocketmanTransport.useInsurance && button1Params.gameState !== 'betInProgress' ? (
        <div className="use-insurance">
          <label className="control control--checkbox">
          <input type="checkbox" checked={useBetInsurance} onChange={() => setBetInsurance(!useBetInsurance)} disabled={button1Params.gameState === 'betActive'} />
          <div className="control__indicator"></div>
          {translate('bet_insurance')}
          </label>
        </div>) : null
      }
      <div
        className={
          "play-now-middle " +
          (button1Params.gameState === "betActive"
            ? "active"
            : button1Params.gameState === "betInProgress"
            ? "in-progress"
            : props.isActive
            ? "in-progress"
            : "")
        }
      >
        {predefinedBets.length &&
          predefinedBets.map((predefinedBet, index) => {
            return (
              <div className="play-now-middle-button-holder" key={index}>
                <button
                className="play-now-middle-button"
                ref={(el) => (buttonRefs.current[index] = el)}
                onClick={() => handleClick(predefinedBet, index)}
              >
                  {currencyFormatter(Number(predefinedBet).toFixed(rocketmanTransport.noOfDecimals))} <Currency className="ml-1">{rocketmanTransport.myCurrency}</Currency>
                </button>
              </div>
            );
          })}
      </div>
      <div
        className={
          "play-now-bottom " +
          (props.isActive
            ? "active"
            : button1Params.gameState === "betInProgress"
            ? "in-progress"
            : "")
        }
      >
        <AutoCashout
          advancedAutoPlay={
            rocketmanTransport.advancedAutoplayInProgress[1].inProgress  || button1Params.gameState !== "initialState"
          }
          betValue={button1Params.val}
          cashoutText={translate("set_auto_cashout")}
          gameState={button1Params.gameState}
          setAutoCashValue={setAutoCashValue}
          autoBet={button1Params.autoBet}
          checkMinAutoCashout={checkMinAutoCashout}
          autoCashValue={button1Params.autoCashout}
          currentBet={button1Params.val}
          buttonIndex={1}
          ticketId={button1Params.ticketId}
          innerRef={autoCashoutRef}
          ticketType={button1Params.ticketType} 
          minCashoutMultiplier={button1Params.minCashoutMultiplier} 
          setDisabledCashout={setDisabledCashout}
        />
        <button
          onClick={() => setShowAdvancedAutoPlay(!showAdvancedAutoPlay)}
          className={
            "advanced-autoplay-button " +
            (button1Params.gameState === "betInProgress " ? "in-progress " : "") +
            (rocketmanTransport.r7css ? "r7css" : "")
          }
        >
          {translate("advanced_autoplay")}
        </button>
        {rocketmanTransport.advancedAutoplayInProgress[1].inProgress ? 
          (<button 
            onClick={() => 
              button1Params.gameState === "betActive"
              ? handleInBetActiveTurnOffAdvancedAutoPlay() :
              handleInProgressTurnOffAdvancedAutoPlay()
              }
            className={"advanced-autoplay-button stop-autoplay " + (rocketmanTransport.r7css ? "r7css" : "")} >
            {translate("stop_autoplay")}
          </button>) : null}
        <div
          className={
            "auto-cashout-holder-preview " +
            (button1Params.gameState === "betInProgress " ? "in-progress " : "") + 
            (rocketmanTransport.r7css ? "r7css" : "")
          }
        >
          <p>{translate("bet_amount")}</p>
          <input disabled className="auto-cashout" value={currencyFormatter(button1Params.val)} />
        </div>
        {button1Params.gameState === "betInProgress" &&
        !button1Params.autoCashout ? null : (
          <div
            className={
              "auto-cashout-holder-preview " +
              (button1Params.gameState === "betInProgress " ? "in-progress " : "") +
              (rocketmanTransport.r7css ? "r7css" : "")
            }
          >
            <p>{translate("cashout_at")}</p>
            <input
              disabled
              className="auto-cashout"
              value={button1Params.autoCashout ? button1Params.autoCashout : ""}
            />
          </div>
        )}
        {!rocketmanTransport.advancedAutoplayInProgress[1].inProgress ? button1Params.gameState === "betInProgress" &&
        !button1Params.autoBet ? null : (
          <div className={"auto-cashout-toogle-holder " + (rocketmanTransport.r7css ? "r7css" : "")}>
            <p>{translate("set_auto_bet")}</p>
            <Switch
              disabled={
                button1Params.ticketType === 3 ||
                rocketmanTransport.advancedAutoplayInProgress[1].inProgress
              }
              onChange={() => handleChangeAutoBet(null, !button1Params.autoBet)}
              checked={Boolean(button1Params.autoBet)}
              className={
                button1Params.autoBet
                  ? "move-left react-switch react-switch-custom"
                  : "react-switch react-switch-custom"
              }
              onColor="#202558"
              offColor="#202558"
              borderRadius={28}
              // width={100}
              height={32}
              // handleDiameter={28}
              activeBoxShadow="0px 0px 15px rgba(255, 191, 26, 0.8)"
              uncheckedIcon={
                <div className={"switcher-on-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("on")}</div>
              }
              checkedIcon={
                <div className={"switcher-off-inactive " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("off")}</div>
              }
              uncheckedHandleIcon={
                <div className={"switcher-off " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("off")}</div>
              }
              checkedHandleIcon={
                <div className={"switcher-on " + (rocketmanTransport.r7css ? "r7css" : "")}>{translate("on")}</div>
              }
            />
          </div>
        ): null}
      </div>
    </div>
  );
}
