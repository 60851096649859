import moment from "moment";
import React, { useEffect, useState } from "react";
import { currencyFormatter } from "../../services/Helpers/NumberFormatter";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import BetWinsItem from "../BetWinsItem";

const TopOddsTab = ({timeFilter}) => {

  const [bets, setBets] = useState([]);
  const currency = rocketmanTransport.myCurrency;
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  
  window.setTopOddsTabLang = setLanguage;

  useEffect(() => {
    switch (timeFilter) {
      case translate('week'):
        rocketmanTransport.requestTopOddsWeekly();
        break;
      case translate('month'):
        rocketmanTransport.requestTopOddsMonthly();
        break;
      case translate('year'):
        rocketmanTransport.requestTopOddsYearly();
        break;
      default:
        break;
    }
  },[timeFilter]);

  window.setTopOdds = setBets;

  return (
    <div>
      {bets.map((bet,index) => {
        return (
          <BetWinsItem key={index} avatar={`avatar${bet.avatar_id}`} ticketId={bet.ticket_id} username={bet.username}
            flagCode={bet.country_code && bet.country_code == 'XK' && language === 'sr' ? 'rs' : bet.country_code} 
            cashedOut={Number(bet.mp).toFixed(2)} 
            roundId={bet.round_id} currency={currency} betTime={moment.utc(bet.created_time).local().format("DD.MM.  HH:mm")} bet={bet.bet} betEur={bet.bet}
            betWin={Number(Number(bet.win_eur) * Number(rocketmanTransport.myExchangeRate))}
            winEur={bet.win_eur}
            win={bet.win}
            betCurrency={bet.currency_code}
            winAmount={rocketmanTransport.myCurrency == bet.currency_code ? currencyFormatter(Number(bet.win)) :  currencyFormatter(Number(Number(bet.win_eur) * Number(rocketmanTransport.myExchangeRate)))} 
            isVip={bet.avatar_id > 49 ? true : false}
           />
        )     
      })}
    </div>
  );
};

export default TopOddsTab;
