import React, { useEffect, useMemo, useState } from "react";
import LogoSvg from "../svg/logo-svg";
import ElbetLogoSvg from "../svg/elbet-logo-svg";
import HeaderCredit from "../HeaderCredit";
import HeaderAvatar from "../HeaderAvatar";
import HeaderAvatarDesktop from "../HeaderAvatarDesktop";
import HeaderBonusGames from "../HeaderBonusGames";
import { useHistory } from "react-router-dom";
import soundFxPlayer from "../../services/Audio/SoundFxPlayer";
// import AlienSaucerFreeBets from "../pop-ups/AlienSaucerFreeBets";
import rocketmanTransport, {
} from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
// import AstronautPromoCredit from "../pop-ups/AstronautPromoCredit";
import VipPlayer from "../pop-ups/VipPlayer";
import JackpotPopups from "../pop-ups/JackpotPopups";
import { currencyFormatter } from "../../services/Helpers/NumberFormatter";
import Clock from "./Clock";
import UncreditedWins from "../UncreditedWins";
import storage from "../../services/Storage/Storage";
import TournamentWinner from "../pop-ups/TournamentWinner";
// import AdvancedAutoplayPromo from "../pop-ups/AdvancedAutoplayPromo";
import InsurancePromo from "../pop-ups/InsurancePromo";
import useTabVisibility from "../../hooks/useTabVisibility";
import musicPlayer from "../../services/Audio/MusicPlayer";

const Header = (props) => {
  const history = useHistory();

  history.listen((location, action) => {
    soundFxPlayer.volume("controlBTN", 0.1);
    soundFxPlayer.play("controlBTN");

    if (location.pathname === "/menu") {
      soundFxPlayer.volume("screen_transition_2", 0.1);
      soundFxPlayer.play("screen_transition_2");
    }
    if (location.pathname === "/bets") {
      soundFxPlayer.play("all_bets_list_appear_1");
    }
  });
  
  useTabVisibility();

  const [startBtnWaitForResponse, setStartBtnWaitForResponse] = useState(false);
  const [promoCreditVal, setPromoCreditVal] = useState(0);

  const [showVipPlayerPopUp, setShowVipPlayerPopUp] = useState(false);
  // const [showAdvancedAutoplayPromo, setShowAdvancedAutoplayPromo] = useState(false);
  const [showInsurancePromo, setShowInsurancePromo] = useState(false);
  const [showJackpotPanel, setShowJackpotPanel] = useState(0);
  const [jackpotValue, setJackpotValue] = useState(0);
  const [minCashoutMultiplier, setMinCashoutMultiplier] = useState(0);
  const [tournamentWinnerBalance, setTournamentWinnerBalance] = useState(0);
  const [tournamentWinnerNotif, setTournamentWinnerNotif] = useState(false);

  window.setPromoCreditVal = setPromoCreditVal;
  window.setMinCashoutMultiplier = setMinCashoutMultiplier;

  window.setTournamentWinnerBalance = setTournamentWinnerBalance;
  window.setTournamentWinnerNotif = setTournamentWinnerNotif;


  window.setShowJackpotPanel = setShowJackpotPanel;
  window.setJackpotValue = setJackpotValue;
  window.setStartBtnWaitForResponse = setStartBtnWaitForResponse;
  // window.setShowAdvancedAutoplayPromo = setShowAdvancedAutoplayPromo;
  window.setShowInsurancePromo = setShowInsurancePromo;

  // const setShowAdvancedAutoplayPromoFunc = (val) => {
  //   setShowAdvancedAutoplayPromo(val);
  //   rocketmanTransport.setShowAdvancedAutoplayPromo(val);
  // } 

  const setShowInsurancePromoFunc = (val) => {
    setShowInsurancePromo(val)
    rocketmanTransport.setShowInsurancePromo(val);
  }

  const [showStartButton, setShowStartButton] = useState(true);
  window.setShowStartButton = setShowStartButton;

  window.setIsVipModal = (d) => {
    if (storage.get('isVip')) {
      return;
    }
    setShowVipPlayerPopUp(d);
    storage.set('isVip',d);
  };

  return (
    <>
      <div
        className={
          (props?.width < 1024 && rocketmanTransport.launcherHideHeader == 1) || rocketmanTransport.retail
            ? "header hide"
            : "header"
        }
      >
        <div className="container header-container">
          <div className="fullscreen-clock">
            {useMemo(
              () => (
                <Clock />
              ),
              []
            )}
          </div>
          <div className="brand-holder">
            <LogoSvg className="mr-2" />
            <h6 className="text-white mr-1">Rocketman</h6>
            <h6 className="text-white mr-1 opacity-30">by</h6>
            <ElbetLogoSvg />
          </div>
          <div className="header-right">
            {useMemo(() => props.freeBetsCount || props.promoBalance ? (
              <HeaderBonusGames
                className="header-bonus-games-desktop"
                betCount={props.freeBetsCount}
                betMoney={props.freeBetsMoney}
                setShowStartButton={setShowStartButton}
                showStartButton={showStartButton}
                promoCredit={props.promoBalance}
                startBtnWaitForResponse={startBtnWaitForResponse}
                minCashoutMultiplier={minCashoutMultiplier}
                // promoCreditVal={promoCreditVal}
              />
            ) : null, [props.freeBetsCount,props.promoBalance,props.freeBetsMoney,showStartButton,startBtnWaitForResponse, minCashoutMultiplier])}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <HeaderCredit />
              <span className="uncredited-wins-desktop"><UncreditedWins uncreditedWins={props.uncreditedWins} /></span>
            </div>
            <div className="header-avatar-mobile">
              <HeaderAvatar
                username={`${props.username ? translate("hey_username") : ""} ${String(props.username).slice(
                  0,
                  13
                )}`}
                avatarId={props.avatarId}
              />
            </div>
            {/* Due big difference on desktop, created desktop component for header avatar */}
            <div className="header-avatar-desktop">
              <HeaderAvatarDesktop
                username={`${props.username ? translate("hey_username") : ""} ${String(props.username).slice(
                  0,
                  13
                )}`}
                avatarId={props.avatarId}
              />
            </div>
          </div>
        </div>
      </div>
      <VipPlayer
        setShow={setShowVipPlayerPopUp}
        show={showVipPlayerPopUp}
        promoText={translate("vip_popup_title")}
      />
      {/* <AdvancedAutoplayPromo
        setShow={setShowAdvancedAutoplayPromoFunc}
        show={showAdvancedAutoplayPromo}
        promoText={translate("try_advanced_autoplay")}
      /> */}
      <InsurancePromo setShow={setShowInsurancePromoFunc} show={showInsurancePromo} promoText={translate("insure_your_bet")} />
      <TournamentWinner
        setShow={setTournamentWinnerNotif}
        show={tournamentWinnerNotif}
        bonusCredit={tournamentWinnerBalance}
        promoText={"Tournament Award"}
      />
      <JackpotPopups
        {...(Number(showJackpotPanel) === 1
          ? { jacketpot: true }
          : Number(showJackpotPanel) === 2
          ? { rocketpot: true }
          : { boosterpot: true })}
        setShow={setShowJackpotPanel}
        show={Boolean(showJackpotPanel)}
        bonusCash={currencyFormatter(jackpotValue)}
      />
    </>
  );
};

export default Header;
