import rocketmanTransport from "../RocketmanTransport";

// Helper function to truncate numbers to a specified number of decimals
export const truncateToDecimals = (num, decimals) => {
  const factor = Math.pow(10, decimals);
  return Math.trunc(num * factor) / factor;
};

const customCurrencyFormatter = (amount, format) => {
  let truncatedAmount = amount;
  let formattedAmount = "";

  if (format === "###,###.00") {
    truncatedAmount = truncateToDecimals(amount, 2);
    formattedAmount = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(truncatedAmount);
  } else if (format === "###,###K") {
    if (amount >= 1000) {
      truncatedAmount = truncateToDecimals(amount / 1000, 0);
      formattedAmount = `${new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(truncatedAmount)}K`;
    } else {
      truncatedAmount = truncateToDecimals(amount, 0);
      formattedAmount = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(truncatedAmount);
    }
  } else if (format === "###,###") {
    truncatedAmount = truncateToDecimals(amount, 0);
    formattedAmount = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(truncatedAmount);
  }

  return formattedAmount;
};

export const currencyFormatter = (
  number,
  format = rocketmanTransport.amountFormat
) => {
  if (format) {
    return customCurrencyFormatter(number, format);
  } else {
    const decimals =
      rocketmanTransport.currencyNumberOfDigits ??
      rocketmanTransport.noOfDecimals ??
      2; // Default to 2 decimals if nothing is set

    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(truncateToDecimals(number, decimals));
  }
};
