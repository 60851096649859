import React, { useEffect, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BoosterPotHolder from "./BetComponent/Header/BoosterPotHolder";
import JackpotHolder from "./BetComponent/Header/JackpotHolder";
import RocketPotHolder from "./BetComponent/Header/RocketPotHolder";
import rocketmanTransport, { urlParams } from "../services/RocketmanTransport";
import translate from "../services/Translation/lang";
import JackpotTableTr from "./JackpotTableTr";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";
import moment from "moment";

const REQUEST_JACKPOT_INTERVAL = 30000;

const JackpotsHeader = (props) => {
  const [initialGameStart, setInitialGameStart] = useState(true);
  const [showJacketpot, setShowJacketpot] = useState(true);
  const [showRocketpot, setShowRocketpot] = useState(true);
  const [showBoosterpot, setShowBoosterpot] = useState(true);
  const [jackpotHistory, setJackpotHistory] = useState([]);
  const [rocketPotHistory, setRocketPotHistory] = useState([]);
  const [boosterPotHistory, setBoosterPotHistory] = useState([]);
  const [headerJackpotData, setHeaderJackpotData] = useState({});

  window.setInitialGameStart = setInitialGameStart;
  window.setJackpotHistory = setJackpotHistory;
  window.setRocketPotHistory = setRocketPotHistory;
  window.setBoosterPotHistory = setBoosterPotHistory;
  window.setHeaderJackpotData = setHeaderJackpotData;

  const handleJacketpot = () => {
    if (!rocketmanTransport.retail) {
      setShowJacketpot(!showJacketpot);
      if (!showRocketpot) {
        setShowRocketpot(!showRocketpot);
      }
      if (!showBoosterpot) {
        setShowBoosterpot(!showBoosterpot);
      }
      if (showJacketpot) {
        rocketmanTransport.requestJackpotHistory(1);
      }
    }
  };
  const handleRocketpot = () => {
      if (!rocketmanTransport.retail) {
      setShowRocketpot(!showRocketpot);
      if (!showJacketpot) {
        setShowJacketpot(!showJacketpot);
      }
      if (!showBoosterpot) {
        setShowBoosterpot(!showBoosterpot);
      }
      if (showRocketpot) {
        rocketmanTransport.requestJackpotHistory(2);
      }
    }
  };

  const handleBoosterpot = () => {
      if (!rocketmanTransport.retail) {
      setShowBoosterpot(!showBoosterpot);
      if (!showJacketpot) {
        setShowJacketpot(!showJacketpot);
      }
      if (!showRocketpot) {
        setShowRocketpot(!showRocketpot);
      }
      if (showBoosterpot) {
        rocketmanTransport.requestJackpotHistory(3);
      }
    }
  };

  const jacketpotRef = useRef(null);
  const rocketpotRef = useRef(null);
  const boosterpotRef = useRef(null);
  const [clickedOutside, setClickedOutside] = useState(false);

  const handleClickOutside = (e) => {
    if (jacketpotRef.current && !jacketpotRef?.current.contains(e.target)) {
      setClickedOutside(true);
      if (!showJacketpot) {
        setShowJacketpot(!showJacketpot);
      }
    }
    if (rocketpotRef.current && !rocketpotRef?.current.contains(e.target)) {
      setClickedOutside(true);
      if (!showRocketpot) {
        setShowRocketpot(!showRocketpot);
      }
    }
    if (boosterpotRef.current && !boosterpotRef?.current.contains(e.target)) {
      setClickedOutside(true);
      if (!showBoosterpot) {
        setShowBoosterpot(!showBoosterpot);
      }
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    rocketmanTransport.requestJackpotData(rocketmanTransport.launcherCompanyId);
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const refreshJackpotValues = () => {
    rocketmanTransport.requestJackpotData(rocketmanTransport.launcherCompanyId);
    setInitialGameStart(false);
  };

  useEffect(() => {
    var refreshJackpotInterval = setInterval(() => {
      refreshJackpotValues();
    }, REQUEST_JACKPOT_INTERVAL);

    return () => clearInterval(refreshJackpotInterval);
  }, []);
  return (
    <div className={"jackpots-header " + (rocketmanTransport.retail ? "retail" : " ")}>
      <div className="container">
        <Row className="gutter-3">
          {headerJackpotData?.jackpot ? (
            <Col
              className="d-flex justify-content-center"
              ref={jacketpotRef}
              onClick={handleClickInside}
            >
              <div
                className={
                  "jackpot-holder " +
                  (props.jackpotActive ? "jackpot-active " : " ") +
                  (initialGameStart ? "on-load " : " ") + 
                  (rocketmanTransport.r7css ? "r7css" : "")
                }
                onClick={handleJacketpot}
              >
                <JackpotHolder />
                {rocketmanTransport.retail ? null : <span className={"jackpot-title " + (rocketmanTransport.r7css ? "r7css" : "")}>jackpot</span>}
              </div>
              <div
                className={
                  "jackpot-info-holder " + (showJacketpot ? "hide" : "")
                }
              >
                <div className="jackpot-info-title">
                  {translate("jackpot_winners")}
                  <button
                    className="close-container bet-info-close"
                    onClick={() => setShowJacketpot(!showJacketpot)}
                  >
                    <div className="leftright"></div>
                    <div className="rightleft"></div>
                  </button>
                </div>
                <table className="bets-table my-bets-table">
                  <thead>
                    <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
                      <th>{translate("date")}</th>
                      <th>{translate("time")}</th>
                      <th>{translate("win")}</th>
                      <th>{translate("user")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jackpotHistory.map((history, i) => {
                      // Combine date and time
                      const combinedDateTime = `${history.jackpot_date} ${history.jackpot_time}`;
                      
                      // Parse as UTC and convert to local time
                      const localDateTime = moment.utc(combinedDateTime, 'YYYY-MM-DD HH:mm').local();
                      
                      // Format date and time
                      const localDate = localDateTime.format('YYYY-MM-DD'); 
                      const localTime = localDateTime.format('HH:mm:ss'); 
                      return (
                        <JackpotTableTr
                          key={i}
                          date={localDate}
                          time={localTime}
                          jackpotWinAmount={history.jackpot_currency == rocketmanTransport.myCurrency ? currencyFormatter(Number(history.jackpot_value))
                             : currencyFormatter(Number(
                            Number(history.jackpot_value_eur) *
                              Number(rocketmanTransport.myExchangeRate)
                          ))}
                          username={history.username}
                          flagCode={history.country_code}
                          avatar={`avatar${history.avatar_id}`}
                          isVip={history.avatar_id > 49 ? true : false}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          ) : null}
          {headerJackpotData?.rocketpot ? (
            <Col
              className="d-flex justify-content-center"
              ref={rocketpotRef}
              onClick={handleClickInside}
            >
              <div
                className={
                  "jackpot-holder " +
                  (props.rocketpotActive ? "jackpot-active " : " ") +
                  (initialGameStart ? "on-load " : " ") +
                  (rocketmanTransport.r7css ? "r7css" : "")
                }
                onClick={handleRocketpot}
              >
                <RocketPotHolder />
                {rocketmanTransport.retail ? null : <span className={"jackpot-title " + (rocketmanTransport.r7css ? "r7css" : "")}>rocketpot</span>}
              </div>
              <div
                className={
                  "jackpot-info-holder rocketpot-info-holder " +
                  (!jacketpotRef.current ? "no-jackpot " : "") +
                  (!boosterpotRef.current ? "no-boosterpot " : "") +
                  (showRocketpot ? "hide" : "")
                }
              >
                <div className="jackpot-info-title">
                  {translate("rocketpot_winners")}
                  <button
                    className="close-container bet-info-close"
                    onClick={() => setShowRocketpot(!showRocketpot)}
                  >
                    <div className="leftright"></div>
                    <div className="rightleft"></div>
                  </button>
                </div>
                <table className="bets-table my-bets-table">
                  <thead>
                    <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
                      <th>{translate("date")}</th>
                      <th>{translate("time")}</th>
                      <th>{translate("win")}</th>
                      <th>{translate("user")}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {rocketPotHistory.map((history,i) => {
                     // Combine date and time
                     const combinedDateTime = `${history.jackpot_date} ${history.jackpot_time}`;
                      
                     // Parse as UTC and convert to local time
                     const localDateTime = moment.utc(combinedDateTime, 'YYYY-MM-DD HH:mm').local();
                     
                     // Format date and time
                     const localDate = localDateTime.format('YYYY-MM-DD'); 
                     const localTime = localDateTime.format('HH:mm'); 
                    return (<JackpotTableTr 
                      key={i}
                      date={localDate} 
                      time={localTime} 
                      jackpotWinAmount={history.jackpot_currency == rocketmanTransport.myCurrency ? currencyFormatter(Number(history.jackpot_value)) : 
                        currencyFormatter(Number(Number(history.jackpot_value_eur) * Number(rocketmanTransport.myExchangeRate)))} 
                      username={history.username}
                      flagCode={history.country_code}
                      avatar={`avatar${history.avatar_id}`}
                      isVip={history.avatar_id > 49 ? true : false}
                      />)
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          ) : null}
          {headerJackpotData?.boosterpot ? (
            <Col
              className="d-flex justify-content-center"
              ref={boosterpotRef}
              onClick={handleClickInside}
            >
              <div
                className={
                  "jackpot-holder " +
                  (props.boosterpotActive ? "jackpot-active " : " ") +
                  (initialGameStart ? "on-load " : " ") +
                  (rocketmanTransport.r7css ? "r7css" : "")
                }
                onClick={handleBoosterpot}
              >
                <BoosterPotHolder />
                {rocketmanTransport.retail ? null : <span className={"jackpot-title " + (rocketmanTransport.r7css ? "r7css" : "")}>boosterpot</span>}
              </div>
              <div
                className={
                  "jackpot-info-holder boosterpot-info-holder " +
                  (showBoosterpot ? "hide" : "")
                }
              >
                <div className="jackpot-info-title">
                  {translate("boosterpot_winners")}
                  <button
                    className="close-container bet-info-close"
                    onClick={() => setShowBoosterpot(!showBoosterpot)}
                  >
                    <div className="leftright"></div>
                    <div className="rightleft"></div>
                  </button>
                </div>
                <table className="bets-table my-bets-table">
                  <thead>
                    <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
                      <th>{translate("date")}</th>
                      <th>{translate("time")}</th>
                      <th>{translate("win")}</th>
                      <th>{translate("user")}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {boosterPotHistory.map((history,i) => {
                     // Combine date and time
                     const combinedDateTime = `${history.jackpot_date} ${history.jackpot_time}`;
                      
                     // Parse as UTC and convert to local time
                     const localDateTime = moment.utc(combinedDateTime, 'YYYY-MM-DD HH:mm').local();
                     
                     // Format date and time
                     const localDate = localDateTime.format('YYYY-MM-DD'); 
                     const localTime = localDateTime.format('HH:mm'); 
                    return (<JackpotTableTr 
                      key={i}
                      date={localDate} 
                      time={localTime} 
                      jackpotWinAmount={history.jackpot_currency == rocketmanTransport.myCurrency ? currencyFormatter(Number(history.jackpot_value)) : 
                        currencyFormatter(Number(Number(history.jackpot_value_eur) * Number(rocketmanTransport.myExchangeRate)))} 
                      username={history.username}
                      flagCode={history.country_code}
                      avatar={`avatar${history.avatar_id}`}
                      isVip={history.avatar_id > 49 ? true : false}
                      />)
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
    </div>
  );
};

export default JackpotsHeader;
